<script>
import Layout from "@/views/layouts/main";
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config";
import { get_open_issues } from "@/config/api/report_image";

export default {
  page: {
    title: "Reported Images",
    meta: [{ name: "description", content: appConfig.description }],
  },
  components: {
    Layout,
    PageHeader,
  },
  name: "app",
  data() {
    return {
      title: "Project ID: " + this.$route.params.issue_id,
      issue: null,
      image: null,
      showAction: false,
      loading: true,
      selected: null,
      options: [
        { value: null, text: "Actions" },
        { value: "a", text: "Approve" },
        { value: "b", text: "Reject" },
      ],
    };
  },
  created() {},
  computed: {},
  mounted() {
    this.imageReportById(this.$route.params.issue_id);
  },
  methods: {
    toggleAction() {
      this.showAction = !this.showAction;
    },
    imageReportById(issue_id) {
      this.loading = true;

      const api = get_open_issues.imageReportById;
      api.id = issue_id;
      this.generateAPI(api)
        .then((res) => {
          this.issue = res.data.report;
          this.image = res.data.image;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.$emit("dataLoaded");
          this.loading = false;
        });
    },
    approveImage() {
      console.log("approve");

      this.loading = true;
      const api = get_open_issues.accept;
      api.id = this.$route.params.issue_id;
      console.log(api);
      this.generateAPI(api)
        .then((res) => {
          this.issue = res.data.report;
          this.image = res.data.image;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.$emit("dataLoaded");
          this.loading = false;
        });
    },
    rejectImage() {
      console.log("reject");

      this.loading = true;
      const api = get_open_issues.reject;
      api.id = this.$route.params.issue_id;
      this.generateAPI(api)
        .then((res) => {
          this.issue = res.data.report;
          this.loading = false;
        })
        .catch((err) => {
          console.error(err.response.data);
        })
        .finally(() => {
          this.$emit("dataLoaded");
          this.loading = false;
        });
    },
    openImage() {
      console.log(this.image);
      window.open(this.image.url);
    },
  },
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" />
    <b-card v-if="!loading">
      <div class="row">
        <div class="col">
          <!-- <b-card-text>
            <label>Project ID: </label> {{ this.issue.projectId.project_id }}
          </b-card-text> -->
          <b-card-text>
            <label>Description: </label> {{ this.issue.description }}
          </b-card-text>
          <b-card-text>
            <label>Status: </label> {{ this.issue.status }}
          </b-card-text>
          <b-card-text>
            <label>By: </label> {{ this.issue.reported_by.name }}
          </b-card-text>
          <b-card-text>
            <label>Reported At: </label>
            {{ moment(this.issue.createdAt).format("lll") }}
          </b-card-text>
        </div>
        <div class="col end" v-if="this.image">
          <div class="image-div">
            <div class="row icon-style">
              <button class="action-btn" @click.stop="rejectImage()">
                <i class="ri-close-line"></i>
              </button>
              <button class="action-btn" @click.stop="approveImage()">
                <i class="ri-check-line"></i>
              </button>
            </div>
            <div class="row icon-style">
              <img
                v-bind:src="this.image.url"
                @mouseover="showAction = true"
                @mouseleave="showAction = false"
                @click="openImage"
              />
            </div>
          </div>
        </div>
        <div class="col end" v-else>
          <div
            class="image-div"
            @mouseover="showAction = true"
            @mouseleave="showAction = false"
          >
            <img style="height: 100" />
          </div>
        </div>
      </div>
    </b-card>
    <div v-else class="text-center">
      <pulse-loader color="#505d69" :loading="true"></pulse-loader>
    </div>
  </Layout>
</template>

<style scoped>
.wrapper {
  margin: 0 auto;
  flex-wrap: wrap;
  background-color: white;
}
.white-card {
  background-color: #ffff;
  margin-bottom: 10px;
  padding: 2rem;
}
img {
  border: 1px solid #ddd;
  border-radius: 4px;
  padding: 5px;
  width: 200px;
}
img:hover {
  cursor: pointer;
}
.icon-style {
  justify-content: flex-end;
  font-size: 2.5rem;
}
.image-div {
  position: relative;
  display: inline-block;
  width: 100%;
}
</style>
<style>
.dropdown .dropdown-menu a:hover {
  color: white;
  background: unset;
}
</style>
